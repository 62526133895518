import React from 'react';

function Cancellation() {
  return (
    <div>
      <div>
        <div>
          <h1
            id="cancellation"
            class="font-pj md:text-5xl sm:text-2xl text-center p-8 tracking-tight font-bold"
            data-aos="fade-up"
          >
            Cancellation & Refund
          </h1>
        </div>
        <div
          class="max-w-md mx-auto bg-white rounded-xl  overflow-hidden md:max-w-7xl "
          data-aos="fade-up"
        >
          <div class="md:flex">
            {/* <div class="md:shrink-0">
              <img
                class="h-48 w-full object-cover md:h-full md:w-64 "
                src={Logo}
                alt="Logo"
              />
            </div> */}
            <div class="p-8 ">
              {/* <div class="uppercase tracking-wide  text-gray-600 font-Varela text-2xl sm:text-xl fomt-pj font-bold">
                Innovatix Digital Media
              </div> */}
              {/* <a
                href="#contact"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline "
              >
                “Empower Your Brand, Ignite Your Influence!”
              </a> */}
              <p class="mt-2 text-slate-500 md:text-lg sm:text-base font-Varela">
                If for any reason the Client chooses not to renew services at
                the end of the (The Month) term of this agreement, they must
                submit a written email notification of their intent not to
                proceed to support@innovatixdigitalmedia.com no later than 7
                days before the renewal date. In addition, the Client agrees to
                complete our off-boarding form and participate in a 30-minute
                exit interview with an Innovatix representative to assist us in
                improving our service. Failure to comply with our cancellation
                policy may result in our inability to deliver a refund if
                payment goes through before canceling.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cancellation;
