import React from 'react';

function Privacy() {
  return (
    <div>
      <div>
        <div>
          <h1
            id="privacy"
            class="font-pj md:text-5xl sm:text-2xl text-center p-8 tracking-tight font-bold"
            data-aos="fade-up"
          >
            Privacy Policy
          </h1>
        </div>
        <div
          class="max-w-md mx-auto bg-white rounded-xl  overflow-hidden md:max-w-7xl "
          data-aos="fade-up"
        >
          <div class="md:flex">
            {/* <div class="md:shrink-0">
              <img
                class="h-48 w-full object-cover md:h-full md:w-64 "
                src={Logo}
                alt="Logo"
              />
            </div> */}
            <div class="p-8 ">
              {/* <div class="uppercase tracking-wide  text-gray-600 font-Varela text-2xl sm:text-xl fomt-pj font-bold">
                Innovatix Digital Media
              </div> */}
              {/* <a
                href="#contact"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline "
              >
                “Empower Your Brand, Ignite Your Influence!”
              </a> */}
              <p class="mt-2 text-slate-500 md:text-lg sm:text-base font-Varela">
                No part of the Client’s confidential and proprietary
                information, ideas, business plans, and trade secrets
                (collectively "Confidential Information") shall be shared
                outside of Innovatix without the Client’s express knowledge and
                approval. Innovatix has no right and license to use any of the
                Client’s data, assets, or materials outside the term of the
                agreement, and only to the extent necessary to provide the
                services to the Client. The reproduction, distribution, and sale
                of Innovatix strategies, assets, and materials by anyone but
                Innovatix is strictly prohibited. The Client shall not infringe
                any of Innovatix copyright, patent, trademark, trade secrets, or
                other intellectual property rights. All provided materials,
                assets, strategies, and Confidential Information shared by
                Innovatix and/ or its representatives belong solely and
                exclusively to Innovatix, and may only be used by the Client as
                authorized by Innovatix. Video and phone calls will be recorded
                for quality and training purposes. The Client may request access
                and view recorded calls at any time during the term of service.
                Innovatix reserves the right to distribute excerpts of recorded
                calls and proof of campaign results in our internal marketing
                campaigns for testimonial purposes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
