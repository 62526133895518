import React from 'react';

function Terms() {
  return (
    <div>
      <div>
        <div>
          <h1
            id="terms"
            class="font-pj md:text-5xl sm:text-2xl text-center p-8 tracking-tight font-bold"
            data-aos="fade-up"
          >
            Terms & Conditions
          </h1>
        </div>
        <div
          class="max-w-md mx-auto bg-white rounded-xl  overflow-hidden md:max-w-7xl "
          data-aos="fade-up"
        >
          <div class="md:flex">
            {/* <div class="md:shrink-0">
              <img
                class="h-48 w-full object-cover md:h-full md:w-64 "
                src={Logo}
                alt="Logo"
              />
            </div> */}
            <div class="p-8 ">
              {/* <div class="uppercase tracking-wide  text-gray-600 font-Varela text-2xl sm:text-xl fomt-pj font-bold">
                Innovatix Digital Media
              </div> */}
              {/* <a
                href="#contact"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline "
              >
                “Empower Your Brand, Ignite Your Influence!”
              </a> */}
              <p class="mt-2 text-slate-500 md:text-lg sm:text-base font-Varela">
                By engaging with Innovatix Digital Media, including but not
                limited to using our services, visiting our website, or
                interacting with our content, you agree to be bound by these
                terms and conditions. We offers social media marketing services,
                including strategy development, content creation, advertising
                campaigns,web Development and analytics reporting, as outlined
                in the client agreement or proposal. Clients are responsible for
                providing accurate information and adhering to deadlines and
                guidelines. Fees for services rendered, payment terms, and
                intellectual property rights are detailed in the client
                agreement. Confidentiality of client information is maintained,
                and termination of services can occur with written notice,
                subject to any termination fees or obligations. Innovatix
                Digital Media is not liable for indirect damages, and disputes
                are resolved through arbitration or mediation under the laws of
                Your Jurisdiction. Changes to terms are communicated, and
                continued use of services constitutes acceptance of updated
                terms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
